import React from 'react'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import { s } from '../../style'

const RecruitmentSteps = () => {
  const { lang } = useLangContext()
  const content = {
    title: {
      pl: 'Etapy rekrutacji',
      en: 'Recruitment steps',
    },
    desc: {
      pl: 'Zapraszamy do zapoznania się z procesem rekrutacyjnym w spółkach należących do OMIDA Group. Nasz proces rekrutacyjny został zaprojektowany tak, aby zapewnić transparentność i efektywność na każdym etapie selekcji. Odkryj,&nbsp;jak możesz dołączyć do dynamicznych zespołów naszych spółek i wspólnie z nami budować przyszłość logistyki.',
      en: 'We invite you to familiarize yourself with the recruitment process in the companies belonging to OMIDA Group. Our recruitment process has been designed to ensure transparency and efficiency at every stage of the selection process. Discover how you can join our dynamic teams and together build the future of logistics.',
    },
    steps: [
      {
        title: {
          pl: 'Etap I',
          en: 'Step 1',
        },
        desc: {
          pl: 'Wyślij do nas swoje CV. Wybrani kandydaci zostaną zaproszeni do kolejnego etapu rekrutacji.',
          en: 'Send us your CV. Selected candidates will be invited to the next stage of the recruitment process.',
        },
      },
      {
        title: {
          pl: 'Etap II',
          en: 'Step 2',
        },
        desc: {
          pl: 'Na tym etapie skontaktuje się z Tobą rekruter. Rozmowa odbędzie się telefonicznie lub poprzez spotkanie video. Dzięki temu poznamy się lepiej i poznamy wzajemnie swoje oczekiwania.',
          en: 'Our recruiter will contact you. The conversation will take place by phone or video call. This way we will get to know each other better and learn about each other\'s expectations.',
        },
      },
      {
        title: {
          pl: 'Etap III',
          en: 'Step 3',
        },
        desc: {
          pl: 'Na tym etapie spotkasz się z przedstawicielami zespołu i poznasz szczegóły swojej nowej roli.',
          en: 'At this stage, you will meet with representatives of the team and learn about the details of your new role.',
        },
      },
      {
        title: {
          pl: 'Etap IV',
          en: 'Step 4',
        },
        desc: {
          pl: 'Jeśli poprzednie etapy zakończą się pomyślnie, dostaniesz od nas ofertę. Witaj na pokładzie!',
          en: 'If you are hired, you will receive an offer of employment. Welcome aboard!',
        },
      },
    ],
  }

  return (
    <>
      <section css={sSection}>
        <div css={sSectionInner}>
          <Headline h={2} title={content.title[lang]} desc={content.desc[lang]} large />
        </div>
        <div css={interviewSteps}>
          {content.steps.map((step, index) => (
            <div key={index} css={interviewStepItem}>
              <div css={interviewStepItemInnerWrapper}>
                <div css={interviewStepNumberWrapper}>
                  <span css={interviewStepNumber}>{step.title[lang]}</span>
                </div>
                <p>{step.desc[lang]}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

const sSection = {
  width: '100vw',
  maxWidth: 1500,
  margin: 'auto',
  padding: '0 2rem 2rem',
  marginTop: '4rem',
  [s.lg]: { marginTop: '8rem' },
  [s.md]: {
    padding: '0 4rem',
    marginTop: '6rem'
  },
  [s.xs]: {
    padding: '0 0 2rem',
  }
}

const sSectionInner = {
  [s.xs]: {
    padding: '0 2rem'
  }
}

const interviewSteps = {
  paddingLeft: 'var(--global-content-edge-padding)',
  paddingRight: 'var(--global-content-edge-padding)',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridGap: '50px',
  margin: '5rem 0 0',
  [s.lg_down]: {
    gridTemplateColumns: '1fr',
    gridGap: '1.5rem',
    margin: '2rem 0 0',
  },
  [s.xs]: {
    gridGap: '0'
  }
};

const interviewStepItem = {
  position: 'relative',
  paddingLeft: '80px',
  [s.xs]: {
    paddingLeft: '0px'
  }
};

const interviewStepItemInnerWrapper = {
  background: 'linear-gradient(0deg, #C7C7C7 -3.54%, #D7D7D7 46.06%, #E1E1E1 99.64%)',
  borderRadius: '2rem',
  minHeight: '192px',
  padding: '1rem 1.5rem 1rem 130px',
  display: 'flex',
  alignItems: 'center',
  [s.xs]: {
    borderRadius: '0px',
    padding: '1rem 1.5rem 1rem 110px',
    p: {
      fontSize: '14px'
    }
  }
};

const interviewStepNumberWrapper = {
  position: 'absolute',
  top: '17px',
  left: '17px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  rotate: '45deg',
  width: '160px',
  height: '160px',
  borderRadius: '40px',
  overflow: 'hidden',
  filter: 'drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.50))',
  transform: 'translateZ(0)',
  background: '#6cab40',
  [s.xs]: {
    left: '-85px',
  }
};

const interviewStepNumber = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 800,
  fontSize: '1.5rem',
  textAlign: 'center',
  color: '#fff',
  textTransform: 'uppercase',
  rotate: '-45deg',
  height: '100%',
  width: '100%',
  [s.xs]: {
    fontSize: '1.15rem',
    justifyContent: 'flex-end',
  }
};

export default RecruitmentSteps
