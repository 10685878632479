import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import Grid from '../../components/Grid'
import { s } from '../../style'

const GptwAward = () => {
  const { lang } = useLangContext()
  const content = {
    title: {
      pl: 'GREAT PLACE TO WORK®',
      en: 'GREAT PLACE TO WORK®',
    },
    desc: {
      pl: `<p>Omida Group S.A. została dwukrotnie uhonorowana Certyfikatem Great Place To Work®. Program Great Place to Work® cieszy się popularnością wśród najbardziej innowacyjnych i szanowanych organizacji w Polsce i na świecie. Od ponad 30 lat wspiera firmy w 150 krajach w rozwijaniu nowoczesnej kultury miejsca pracy opartej na zaufaniu i osiąganiu lepszych wyników biznesowych.</p>
      <p>Certyfikat Great Place to Work® przyznawany jest wyłącznie w oparciu o opinie pracowników na temat ich doświadczeń związanych z pracą w firmie.</p>`,
      en: `<p>Omida Group S.A. została dwukrotnie uhonorowana Certyfikatem Great Place To Work®. Program Great Place to Work® cieszy się popularnością wśród najbardziej innowacyjnych i szanowanych organizacji w Polsce i na świecie. Od ponad 30 lat wspiera firmy w 150 krajach w rozwijaniu nowoczesnej kultury miejsca pracy opartej na zaufaniu i osiąganiu lepszych wyników biznesowych.</p>
      <p>Certyfikat Great Place to Work® przyznawany jest wyłącznie w oparciu o opinie pracowników na temat ich doświadczeń związanych z pracą w firmie.</p>`,
    },
  }
  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/kariera/gptw_2024.png/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [WEBP, AVIF]
          )
        }
      }
    }
  `)

  return (
    <Section top>
      <Headline h={2} title={content.title[lang]} large />
      <Grid container ccss={sContainer}>
        <Grid item>
          <div css={sImage}>
            {image && (
              <GatsbyImage style={sImage.image} image={getImage(image)} alt="Great Place To Work" />
            )}
          </div>
        </Grid>
        <Grid item>
          <div css={sDesc} dangerouslySetInnerHTML={{ __html: content.desc[lang] }} />
        </Grid>
      </Grid>
    </Section>
  )
}

const sContainer = {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '48px',
  margin: '3rem 0 2rem',
  paddingRight: '80px',
  [s.sm_down]: {
    gridTemplateColumns: '1fr',
    paddingRight: '0',
    gap: '1rem',
    margin: '2rem 0 0',
  }
}

const sImage = {
  width: '100%',
  height: 'auto',
}

const sDesc = {
  p: {
    margin: '0 0 1rem',
    marginTop: '1.5rem',
    opacity: 0.6,
    lineHeight: '2em',
  },
}

export default GptwAward
