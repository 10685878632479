import React from 'react'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import Grid from '../../components/Grid'
// import { s } from '../../style'

const JobOffers = () => {
  const { lang } = useLangContext()
  const content = {
    title: {
      pl: 'Dołacz do #OmidaTeam 💪',
      en: 'Join #OmidaTeam 💪',
    },
    desc: {
      pl: `Jesteśmy dużą rozpoznawalną firmą transportową. Realizujemy stabilne warunki współpracy, szkolenia branżowe i pracę w jednej z najszybciej rozwijających się polskich firm transportowych. Posiadamy oddziały w ponad 30 miejscowościach w Polsce. Chcesz dołączyć do nas ze swoim zespołem? Skontaktuj się z nami <a href="mailto:hr@omida-vls.com">hr@omida-vls.com</a>.<br/><br/><strong>Chcesz otworzyć oddział spedycyjny w Twoim mieście?</strong><br/>Skontaktuj się z nami i wspólnie otwórzmy oddział w Twojej miejscowości. Jesteśmy w dużych miastach i mniejszych miasteczkach. Sprawdź istniejące oddziały TSL na stronie <a href="omida.pl/spedycja/">omida.pl/spedycja/</a>.`,
      en: `<p>We are a large, recognizable transport company. We offer stable cooperation conditions, industry training and work in one of the fastest growing Polish transport companies. We have branches in over 30 cities in Poland. Do you want to join us with your team? Contact us at <a href="mailto:hr@omida-vls.com">hr@omida-vls.com</a>.</p><br/><p><strong>Do you want to open a spedycja office in your city?</strong><br/>Contact us and together we will open an office in your city. We are in big cities and smaller towns. Check existing TSL offices on the <a href="omida.pl/spedycja/">omida.eu/forwarding/</a> page.</p>`,
    },
  }

  React.useEffect(() => {
    const container = document.querySelector('.jobOfferSection')
    if (!container) return

    const script = document.createElement('script')
    script.id = 'elevato-widget'
    script.type = 'text/javascript'
    script.src = 'https://vls.elevato.net/services/scripts/ElevatoWidgetJobOffers.js?WidgetId=3&WidgetGuid=00a6c668-f9b5-4917-9405-c75eea5b3407'
    script.async = true
    
    container.appendChild(script)

    return () => {
      if (container.contains(script)) {
        container.removeChild(script)
      }
    }
  }, [])

  return (
    <Section top>
      <Headline h={2} title={content.title[lang]} desc={content.desc[lang]} large />
      <Grid container ccss={sContainer}>
        <div className="jobOfferSection">
          <noscript>
            Przepraszamy, funkcja wyszukiwania ogłoszeń jest niedostępna, ponieważ
            Twoja przeglądarka internetowa nie obsługuje java script.
            <a href="https://www.elevatosoftware.com/pl/noscript" target="_blank" rel="noopener">
              Kliknij tutaj
            </a>
            aby dowiedzieć się jak zmienić ustawienia Twojej przeglądarki.
          </noscript>
        </div>
      </Grid>
    </Section>
  )
}

const sContainer = {}

export default JobOffers
